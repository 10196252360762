import { useEffect, useState } from "react";

import { useCopyToClipboard } from "./use-copy-to-clipboard";

type CopyState = "idle" | "loading" | "success";

type Options = {
  loadingDuration?: number;
  successTimeout?: number;
};

export function useCopyWithLoading(options?: Options) {
  const { loadingDuration = 1000, successTimeout = 2000 } = options ?? {};

  const [, copyToClipboard] = useCopyToClipboard();
  const [state, setState] = useState<CopyState>("idle");

  async function copy(text: string) {
    setState("loading");
    await new Promise((resolve) => {
      setTimeout(resolve, loadingDuration);
    });
    await copyToClipboard(text);
    setState("success");
  }

  useEffect(() => {
    if (state !== "success") return;
    const id = setTimeout(() => setState("idle"), successTimeout);
    return () => clearTimeout(id);
  }, [state, successTimeout]);

  return [state, copy] as const;
}
